import React from "react"
import PropTypes from "prop-types"
import Frame from "../components/Frame"
import SEO from "../components/seo"
import ThermalBackground from "../components/ThermalBackground"
import Cushion from "../components/ui/Cushion"
import MaxWidth from "../components/ui/MaxWidth"
import Rectangle from "../components/ui/Rectangle"
import Typography from "../components/ui/Typography"
import ConvexTop from "../components/ConvexTop"
import ConcaveBottom from "../components/ConcaveBottom"
import NoImageSection from "../components/ContentSections/NoImage"
import ImageLeftSection from "../components/ContentSections/ImageLeft"
import ImageRightSection from "../components/ContentSections/ImageRight"

function isOdd(value) {
  if (value % 2 === 0) return false
  else return true
}

const ReasonsForPowerflush = ({ data, location }) => {
  const {
    powerflushBenefitsJson: { title, summary, benefits } = {},
    contactDetailsJson,
  } = data
  const benefitsLength = benefits ? benefits.length : 0

  return (
    <Frame
      currentPathname={location.pathname}
      footerBackgroundColor={isOdd(benefitsLength) ? "white" : "vLightGrey"}
      contactDetails={contactDetailsJson}
    >
      <SEO title={title} />
      <ThermalBackground component="div">
        <ConcaveBottom fill="white">
          <Cushion
            component="div"
            horizontal="small"
            top="xxlarge"
            bottom="xxxxlarge"
            responsive={[
              { minWidth: "palm", props: { horizontal: "medium" } },
              { minWidth: "tab", props: { horizontal: "xlarge" } },
            ]}
          >
            <Typography component="div" align="center">
              <Cushion bottom="large">
                <Cushion bottom="medium">
                  <Typography component="div" preset="hero" color="white">
                    {title}
                  </Typography>
                </Cushion>
                <MaxWidth maxWidth={800} center>
                  <Typography component="div" preset="body" color="white">
                    {summary}
                  </Typography>
                </MaxWidth>
              </Cushion>
            </Typography>
          </Cushion>
        </ConcaveBottom>
      </ThermalBackground>

      {!!benefitsLength &&
        benefits.map(
          (
            {
              title: benefitTitle,
              content: benefitContent,
              image: benefitImage,
            },
            index
          ) => {
            if (isOdd(index)) {
              return (
                <ConcaveBottom fill="white">
                  <ConvexTop fill="vLightGrey" background="white">
                    <Cushion horizontal="large" vertical="xlarge">
                      <Rectangle fill="vLightGrey" component="div">
                        <MaxWidth component="div" maxWidth={980} center>
                          {benefitImage ? (
                            <ImageLeftSection
                              title={benefitTitle}
                              content={benefitContent}
                              image={benefitImage}
                            />
                          ) : (
                            <NoImageSection
                              title={benefitTitle}
                              content={benefitContent}
                            />
                          )}
                        </MaxWidth>
                      </Rectangle>
                    </Cushion>
                  </ConvexTop>
                </ConcaveBottom>
              )
            }
            return (
              <Cushion horizontal="large" vertical="xlarge">
                <Rectangle fill="white" component="div">
                  <MaxWidth component="div" maxWidth={980} center>
                    {benefitImage ? (
                      <ImageRightSection
                        title={benefitTitle}
                        content={benefitContent}
                        image={benefitImage}
                      />
                    ) : (
                      <NoImageSection
                        title={benefitTitle}
                        content={benefitContent}
                      />
                    )}
                  </MaxWidth>
                </Rectangle>
              </Cushion>
            )
          }
        )}
    </Frame>
  )
}

ReasonsForPowerflush.propTypes = {
  location: PropTypes.shape({}).isRequired,
}
export default ReasonsForPowerflush

export const pageQuery = graphql`
  query ReasonsForPowerflushQuery {
    contactDetailsJson {
      email
      mobile
      landline
    }
    powerflushBenefitsJson {
      title
      summary
      benefits {
        title
        image
        content
      }
    }
  }
`
